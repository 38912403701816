<template>
  <div>
    <header-content></header-content>
    <div id="website_download_wrap" style="width: 100%; overflow: hidden" class="recruitment">
      <!-- banner -->
      <div class="banner">
        <div id="join_banner">
          <a>
            <span class="banner_img"></span>
          </a>
        </div>
        <div class="banner_main banner_main_download">
          <h3>加入我们</h3>
        </div>
      </div>
      <div class="content">
        <div class="littleIcon">
          <div class="nof">
            <h1>五险一金</h1>
          </div>
          <div class="nof">
            <h1>定期体检</h1>
          </div>
          <div class="nof">
            <h1>员工旅游</h1>
          </div>
          <div class="nof">
            <h1>节日礼品</h1>
          </div>
        </div>
        <div class="activity_wrap">
          <ul class="activity">
            <li>
              <div class="big ani">
                <img src="../assets/img/join_us01.jpg" />
                <span class="arrow"></span>
              </div>
              <div class="small">
                <h1>户外活动</h1>
              </div>
              <div class="small ani">
                <img src="../assets/img/join_us02.jpg" />
                <span class="arrow arrow2"></span>
              </div>
              <div class="small red">
                <h1>节日聚会</h1>
              </div>
            </li>
            <li>
              <div class="small ani">
                <img src="../assets/img/join_us03.jpg" />
                <span class="arrow arrow1"></span>
              </div>
              <div class="small train">
                <h1>定期培训</h1>
              </div>
              <div class="big ani">
                <img src="../assets/img/join_us04.jpg" />
                <span class="arrow"></span>
              </div>
              <div class="small">
                <h1>亲子活动</h1>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>
<script>
import HeaderContent from "@/components/HeaderContent.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "Join",
  components: {
    HeaderContent,
    FooterContent
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      this.$router.replace('/joinm');
    } else {
      // alert("pc端");
      // this.$router.replace('/join');
    }
  },
  methods: {}
};
</script>

<style scoped>
@import "../assets/css/index.css";
.banner_img {
  background: url("../assets/img/join_bg1.jpg") no-repeat center top;
}
</style>
